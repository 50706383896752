import React, { useEffect, useState } from 'react';
import Loader from 'react-loader-spinner';
import axios from 'axios';
import VodList from './vod_list';
import VodView from './vod_view';
import Paging from './paging';
import queryString from 'query-string';

const Vod = ({ match, history, location }) => {
  const [dataList, setDataList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [totalCount, setTotalCount] = useState(0);
  const [page, setPage] = useState(1);
  const [imgSrc, setImgSrc] = useState();
  const query = queryString.parse(location.search);
  const wr_id = query.wr_id;
  const bbs_id = match.params.bbs_id;

  const fetchDatas = async (page) => {
    try {
      setLoading(true);
      await axios
        .post('https://api1.mutodesign.co.kr/bogaksa/board.php', {
          mode: 'list',
          bbs_id: bbs_id,
          page: page,
        })
        .then((response) => {
          setDataList(response.data);
          setTotalCount(response.data[0].wr_totalCount);
        })
        .catch(function (error) {
          console.log(error);
        });
    } catch (e) {
      console.log(e);
    }
    setLoading(false);
  };

  useEffect(() => {
    setPage(1);
    fetchDatas(1);
    setImgSrc('https://bogaksa.com/data/app/' + bbs_id + '.jpg');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [bbs_id]);
  const handlePageChange = (value) => {
    setPage(value);
    fetchDatas(value);
  };

  if (loading)
    return (
      <div className="wrap loader">
        <Loader type="TailSpin" color="#661900" height={50} width={50} />
      </div>
    );
  return (
    <div className="wrap">
      <div className="container-full">
        {!wr_id && (
          <img
            src={imgSrc}
            className="img-responsive center-block"
            alt="보각사"
          />
        )}

        {wr_id && <VodView bbs_id={bbs_id} wr_id={wr_id} />}
        <div className="container">
          <div className="pull-left">
            <label>총 {totalCount}개</label>
          </div>
          <div className="pull-right"></div>
        </div>

        <section>
          <div className="wf-container">
            <div className="cards">
              <ul>
                {dataList.map((item) => (
                  <VodList
                    key={item.wr_id}
                    item={item}
                    page={page}
                    handlePageChange={handlePageChange}
                  />
                ))}
              </ul>
            </div>
          </div>
        </section>

        <div className="text-center">
          <Paging
            page={page}
            totalCount={totalCount}
            handlePageChange={handlePageChange}
          />
        </div>
      </div>
    </div>
  );
};

export default Vod;
