import React, { useEffect } from 'react';

const Onair = () => {
  useEffect(() => {
    //document.querySelector('#mySidenav').style.width = '0px';
    //document.querySelector('#cover').style.display = 'none';
  });
  return (
    <div className="wrap">
      <div className="container-full">
        <h1 className="main_title">ON AIR</h1>
      </div>
    </div>
  );
};

export default Onair;
