import React, { useEffect } from 'react';

const Map = () => {
  const executeScript = () => {
    const scriptTag = document.createElement('script');
    const inlineScript = document.createTextNode(`new daum.roughmap.Lander({
    	"timestamp" : "1537176418105",
    	"key" : "q35g",
    	"mapHeight" : "360"
    }).render();`);
    scriptTag.appendChild(inlineScript);
    document.body.appendChild(scriptTag);
  };

  const InstallScript = () => {
    (function () {
      //var c = window.location.protocol == 'https:' ? 'https:' : 'http:';
      var c = window.location.protocol;
      var a = '16137cec';

      if (window.daum && window.daum.roughmap && window.daum.roughmap.cdn) {
        return;
      }
      window.daum = window.daum || {};
      window.daum.roughmap = {
        cdn: a,
        URL_KEY_DATA_LOAD_PRE: c + '//t1.daumcdn.net/roughmap/',
        url_protocal: c,
      };
      var b =
        c +
        '//t1.daumcdn.net/kakaomapweb/place/jscss/roughmap/' +
        a +
        '/roughmapLander.js';

      const scriptTag = document.createElement('script');
      scriptTag.src = b;
      document.body.append(scriptTag);
      scriptTag.onload = () => {
        executeScript();

        var mapContainer = document.querySelector(
          '#daumRoughmapContainer1537176418105'
        );
        mapContainer.style.width = '100%';
      };
    })();
  };

  useEffect(() => {
    InstallScript();
  }, []);

  return (
    <>
      <div className="wrap">
        <div className="container-full">
          <h1 className="main_title">오시는 길</h1>
          <div
            id="daumRoughmapContainer1537176418105"
            className="root_daum_roughmap root_daum_roughmap_landing"
          ></div>
        </div>
      </div>
    </>
  );
};
export default Map;
