import React, { useEffect, useState } from 'react';
import Loader from 'react-loader-spinner';
import axios from 'axios';
import VodList from './vod_list';
import VodView from './vod_view';
import img_main from '../images/main.png';
import Paging from './paging';
import queryString from 'query-string';

const Home = ({ location, history }) => {
  const [dataList, setDataList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [totalCount, setTotalCount] = useState(0);
  const [page, setPage] = useState(1);
  const query = queryString.parse(location.search);
  const inKeyword = query.keyword;
  const bbs_id = query.bbs_id;
  const wr_id = query.wr_id;
  const home_id = query.home_id;

  const fetchDatas = async (bbs_id, page, keyword) => {
    try {
      setLoading(true);
      await axios
        .post('https://api1.mutodesign.co.kr/bogaksa/board.php', {
          mode: keyword ? 'search' : 'all',
          page: page,
          bbs_id: bbs_id,
          keyword: keyword,
        })
        .then((response) => {
          setDataList(response.data);
          setTotalCount(response.data[0].wr_totalCount);
          //console.log(response.data);
        })
        .catch(function (error) {
          console.log(error);
        });
    } catch (e) {
      console.log(e);
    }
    setLoading(false);
  };

  useEffect(() => {
    fetchDatas(bbs_id, page, inKeyword);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const handlePageChange = (value) => {
    const query2 = queryString.parse(location.search);
    const inKeyword2 = query2.keyword;
    setPage(value);
    fetchDatas(bbs_id, value, inKeyword2);
    //console.log(value);
  };

  if (loading)
    return (
      <div className="wrap loader">
        <Loader type="TailSpin" color="#661900" height={50} width={50} />
      </div>
    );
  return (
    <div className="wrap">
      <div className="main">
        {wr_id && <VodView bbs_id={home_id} wr_id={wr_id} />}
        {!wr_id && (
          <a href="tel:1636">
            <img
              src={img_main}
              className="img-responsive center-block"
              alt="보각사 회주 법담스님"
            />
          </a>
        )}
      </div>
      <div className="container-full">
        <div className="container">
          <div className="pull-left">
            <label>총 {totalCount}개</label>
          </div>
          <div className="pull-right"></div>
        </div>

        <section>
          <div className="wf-container">
            <div className="cards">
              <ul>
                {totalCount > 0 &&
                  dataList.map((item) => (
                    <VodList
                      key={item.no}
                      item={item}
                      page={page}
                      handlePageChange={handlePageChange}
                      home="true"
                    />
                  ))}
              </ul>
            </div>
          </div>
        </section>

        <div className="text-center">
          <Paging
            page={page}
            totalCount={totalCount}
            handlePageChange={handlePageChange}
          />
        </div>
      </div>
    </div>
  );
};

export default Home;
