import React, { useEffect, useState } from 'react';
import axios from 'axios';

const KeywordFav = () => {
  const [dataList, setDataList] = useState([]);

  const goSearch = (keyword) => {
    document.location.href = '/?keyword=' + keyword;
  };

  useEffect(() => {
    const fetchDatas = async () => {
      try {
        await axios
          .post('https://api1.mutodesign.co.kr/bogaksa/favorite.php')
          .then((response) => {
            setDataList(response.data);
            console.log(response.data);
          })
          .catch(function (error) {
            console.log(error);
          });
      } catch (e) {
        console.log(e);
      }
    };
    fetchDatas();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <ul className="searchFav">
      <li>
        <b>인기검색어</b>
      </li>

      {dataList.map((item) => (
        <li
          key={item.no}
          onClick={() => {
            goSearch(`${item.keyword}`);
          }}
        >
          {item.keyword}
        </li>
      ))}
    </ul>
  );
};

export default KeywordFav;
