import React from 'react';
import { Link } from 'react-router-dom';

const Menu = ({ toggleSidebar, dataMenu }) => {
  //youtube link: vnd.youtube://channel/UCzdPPPkneBy0i9bXS2g2_zA
  return (
    <>
      <div className="sidenav">
        <h1>부산보각사</h1>
        <span className="closebtn" onClick={() => toggleSidebar(false)}>
          &times;
        </span>
        <ul id="navi">
          <li className="group">
            <div className="title">
              <Link to="/vod/a_live2" onClick={() => toggleSidebar(false)}>
                <i className="fa fa-play-circle" aria-hidden="true" />
                &nbsp;법담스님 실시간 방송
              </Link>
            </div>
          </li>
          <li className="group">
            <div className="title">
              <i className="fas fa-video" /> 동영상보기
            </div>
            <ul className="sub">
              {dataMenu.map((item) => (
                <li key={item.no}>
                  <Link
                    to={`/vod/${item.bbs_id}`}
                    onClick={() => toggleSidebar(false)}
                  >
                    {item.title}
                  </Link>
                </li>
              ))}
            </ul>
          </li>
          <li className="group">
            <div className="title">
              <Link to="/intro" onClick={() => toggleSidebar(false)}>
                <i className="fas fa-hotel"></i> 부산보각사
              </Link>
            </div>
            <ul className="sub">
              <li>
                <Link to="/intro" onClick={() => toggleSidebar(false)}>
                  보각사 회주 법담스님
                </Link>
              </li>
              <li>
                <a href="/map">오시는 길</a>
              </li>
            </ul>
          </li>
        </ul>
      </div>
      <div className="cover" />
    </>
  );
};

export default Menu;
