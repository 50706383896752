import React, { Component } from 'react';
import KeywordFav from './keywordFav';

class Header extends Component {
  constructor(props) {
    super(props);

    this.state = {
      showSearch: false,
      keyword: '',
    };

    this.updateInput = this.updateInput.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }
  updateInput(event) {
    this.setState({ keyword: event.target.value });
  }

  handleSubmit() {
    document.location.href = '/?keyword=' + this.state.keyword;
  }
  handleSearch(value) {
    value === 'open'
      ? this.setState({ showSearch: true })
      : this.setState({ showSearch: false });
  }
  handleHome() {
    document.location.href = '/';
  }

  render() {
    return (
      <>
        <header>
          <button
            type="button"
            className="btn"
            onClick={() => this.props.toggleSidebar(true)}
          >
            <i className="fas fa-align-justify fa-2x  icon-white"></i>
          </button>
          <h1>
            <span onClick={this.handleHome} className="white">
              부산보각사
            </span>
          </h1>
          <button
            type="button"
            className="btn right"
            onClick={() => this.handleSearch('open')}
          >
            <i className="fas fa-search fa-2x icon-white"></i>
          </button>
          {this.state.showSearch === true && (
            <div className="white_content">
              <div>
                <span
                  className="close"
                  onClick={() => this.handleSearch('close')}
                >
                  x
                </span>
                <h3>검색</h3>
                <p>검색어를 입력해 주세요.</p>
                <div id="hd_sch_wr">
                  <input type="text" id="sch_stx" onChange={this.updateInput} />
                  <button
                    type="submit"
                    id="sch_submit"
                    onClick={this.handleSubmit}
                  >
                    <i className="fa fa-search" aria-hidden="true" />
                  </button>
                </div>
                <KeywordFav />
              </div>
            </div>
          )}
        </header>
      </>
    );
  }
}

export default Header;
