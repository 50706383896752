import React, { useEffect, useState } from 'react';
import axios from 'axios';
import Iframe from 'react-iframe';

const VodView = (props, { datas }) => {
  const [dataView, setDataView] = useState({});

  const fetchDataView = async (bbs_id, wr_id) =>
    await axios
      .post('https://api1.mutodesign.co.kr/bogaksa/board.php', {
        mode: 'view',
        bbs_id,
        wr_id,
      })
      .then((response) => {
        setDataView(response.data);
      })
      .catch(function (error) {
        console.log(error);
      });

  useEffect(() => {
    fetchDataView(props.bbs_id, props.wr_id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.bbs_id, props.wr_id]);

  console.log(datas);

  return (
    <>
      <div className="cards-main">
        <div className="vod">
          <Iframe
            url={dataView.wr_url}
            width="100%"
            height="250px"
            className="vod-iframe"
            display="initial"
            position="relative"
            allowfullscreen="1"
            allow="fullscreen; accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          />
        </div>
        <div className="content">
          <p>{dataView.wr_kind}</p>
          <h3>{dataView.wr_subject}</h3>
        </div>
      </div>
    </>
  );
};

export default VodView;
