import React from 'react';
import '../css/paging.css';
import Pagination from 'react-js-pagination';

const Paging = ({ page, totalCount, handlePageChange }) => {
  return (
    <Pagination
      activePage={page}
      itemsCountPerPage={12}
      totalItemsCount={totalCount}
      pageRangeDisplayed={5}
      prevPageText={'‹'}
      nextPageText={'›'}
      hideDisabled
      hideFirstLastPages
      itemClass="page-item"
      onChange={handlePageChange}
    />
  );
};

export default Paging;
