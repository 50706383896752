import React, { useState, useEffect } from 'react';
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
} from 'react-router-dom';
import axios from 'axios';
import './app.css';
import Header from './components/header';
import Menu from './components/menu';
import Footer from './components/footer';
import Home from './components/home';
import Intro from './components/intro';
import Map from './components/map';
import Vod from './components/vod';
import Onair from './components/onair';

const App = () => {
  const [showSidebar, setShowSidebar] = useState(false);
  const [dataMenu, setDataMenu] = useState([]);

  useEffect(() => {
    const fetchMenu = async () => {
      try {
        await axios
          .post('https://api1.mutodesign.co.kr/bogaksa/menu.php')
          .then((response) => {
            console.log('menu loaded');
            setDataMenu(response.data);
          })
          .catch(function (error) {
            console.log(error);
          });
      } catch (e) {
        console.log(e);
      }
    };
    fetchMenu();
  }, []);

  const toggleSidebar = (value) => {
    setShowSidebar(value);
  };

  return (
    <Router>
      <Header toggleSidebar={toggleSidebar} />
      {showSidebar && (
        <Menu toggleSidebar={toggleSidebar} dataMenu={dataMenu} />
      )}
      <Switch>
        <Route path="/" exact component={Home} />
        <Route path="/vod/:bbs_id" component={Vod} />
        <Route path="/onair" component={Onair} />
        <Route path="/intro" component={Intro} />
        <Route path="/map" component={Map} />
        <Redirect path="*" to="/" />
      </Switch>
      <Footer />
    </Router>
  );
};

export default App;
