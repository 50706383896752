import React from 'react';
import image_bogaksa from '../images/bogaksa.jpg';

const Intro = () => {
  return (
    <>
      <div className="wrap">
        <div className="container-full">
          <h1 className="main_title">보각사 회주 법담스님</h1>
          <img
            src={image_bogaksa}
            alt="보각사 회주 법담스님"
            className="img-responsive center-block"
          />
        </div>
      </div>
    </>
  );
};

export default Intro;
