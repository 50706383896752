import React from 'react';
import { Link } from 'react-router-dom';

const VodList = ({ item, handlePageChange, page, home }) => {
  const { bbs_id, wr_id, wr_kind, wr_subject, wr_image } = item;
  const urlView = home
    ? `/?home_id=${bbs_id}&wr_id=${wr_id}`
    : `/vod/${bbs_id}?wr_id=${wr_id}`;

  return (
    <li>
      <Link to={urlView} onClick={() => handlePageChange(page)}>
        <div className="vod">
          {wr_image ? <img src={wr_image} alt={wr_subject} /> : 'no image'}
        </div>
        <div className="content">
          <p>{wr_kind}</p>
          <h3>{wr_subject}</h3>
        </div>
      </Link>
    </li>
  );
};

export default VodList;
