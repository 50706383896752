import React, { Component } from 'react';

class Footer extends Component {
  render() {
    return (
      <div>
        <footer>Copyright © bogaksa.com. All rights reserved.</footer>
      </div>
    );
  }
}

export default Footer;
